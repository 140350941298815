import React from "react"
import { formatPct, formatTimestamp} from "../../utils"
import ThumbsUpDownOutlinedIcon from '@mui/icons-material/ThumbsUpDownOutlined';
import RedoIcon from '@mui/icons-material/Redo';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import TimerIcon from '@mui/icons-material/Timer';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import stocktwitslogo from "../../images/stocktwits.png"

export default class SocialStats extends React.Component {
    state = {
        socialdata: null
    }
    
    //the widget should not be visible if we don't have a ticker
    haveValidTicker = () => (this.props.ticker && this.props.ticker !== "");
    haveSocialData = () => (this.state.socialdata && this.state.socialdata !== null);
    shouldShowData = () => (this.haveValidTicker()===true && this.haveSocialData()===true);
    
    refreshSocialData() {
        //clear out prior data
        this.setState({socialdata:null});

        if (this.haveValidTicker() === false) return; //leave component cleared out if we don't have a ticker

        const url = `${process.env.GATSBY_TICKER_API_ENDPOINT}/${this.props.ticker}/social`;
        console.log(url);
        fetch(url)
            .then(res => res.json())
            .then((data) => this.setState({ socialdata: data }) )
            .catch(console.log);
    }
   
    //if a new ticker has been loaded, refresh the expiry list
    componentDidUpdate(prevProps) {
        if (prevProps.ticker !== this.props.ticker) {
            this.refreshSocialData();
        }
    }

    componentDidMount() {
        this.refreshSocialData()
    }
    
    
    render(){
        if (this.shouldShowData()===false) {
            return <div><CircularProgress variant="indeterminate" thickness={2.0} color="primary" /></div>
        }
        let socialData=this.state.socialdata;
        console.log('socialData:');
        console.log(socialData);
        return (
            <div>
                <h6 className="card-subtitle">Analyzing the last {socialData.messageCount} messages on <OutboundLink href={'https://stocktwits.com/symbol/'+this.props.ticker} rel="noreferrer noopener"  target='_blank'>
                    <img src={stocktwitslogo}  style={{verticalAlign:"middle"}} height="25" alt="stockTwits"/></OutboundLink>
                </h6>
                
                <div className="font-light">
                    <h6 className="card-title">Sentiment metrics</h6>
                    <Table width='100%' size="small" sx={{[`& .${tableCellClasses.root}`]: {p: .25,borderBottom: "none", whiteSpace: "nowrap"}}}>
                        <TableBody>
                            <TableRow>
                                <td>Bear/bull ratio:</td>
                                <td><Tooltip title="Of the posts for which sentiment can be determined, how many are bullish vs. bearish"
                                aria-label="Of the posts for which sentiment can be determined, how many are bullish vs. bearish">
                                    <ThumbsUpDownOutlinedIcon style={{  fontSize:20, color: "#cccccc" }} />
                                    </Tooltip>
                                    </td>
                                <td>{formatPct(socialData.sentiment.bearRatio*100)}  /  {formatPct(socialData.sentiment.bullRatio*100)} </td>
                            </TableRow>
                            <TableRow>
                                <TableCell>No sentiment:</TableCell>
                                <TableCell>
                                    <Tooltip title="Many posts don't declare a sentiment">
                                    <SentimentSatisfiedIcon style={{  fontSize:20, color: "#cccccc" }}/>
                                </Tooltip>
                                </TableCell>
                                <TableCell>{formatPct(socialData.sentiment.noSentiment*100)} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reference other tickers:</TableCell>
                                <TableCell>
                                    <Tooltip title={"How many of these posts also refer to other tickers (and thus might not strictly be about "+this.props.ticker+"?"} >
                                    <RedoIcon style={{ fontSize:20, color: "#cccccc" }}/>
                                    </Tooltip>
                                    </TableCell>
                                <td>{formatPct( (socialData.crossRef.count/socialData.messageCount)*100)} </td>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <h6 className="card-title">Message Velocity:</h6>
                    <Table width="100%" size="small" sx={{[`& .${tableCellClasses.root}`]: {p: .25,borderBottom: "none", whiteSpace: "nowrap"}}}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Time since last post</TableCell>
                            <TableCell><Tooltip title={"Time since last post tells us whether anyone is actively discussing "+this.props.ticker} >
                                <TimelapseIcon style={{ fontSize:20, color: "#cccccc" }}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell>{formatTimestamp(socialData.messageVelocity.lastPostAge)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>100 posts in the last:</TableCell>
                            <TableCell>
                                <Tooltip title={"The age of the 100th most recent post tells us how rapidly people are posting about "+this.props.ticker}>
                                    <TimerIcon style={{ fontSize:20, color: "#cccccc" }}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell>{formatTimestamp(socialData.messageVelocity.hundredthPostAge)}</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
            </div>
        </div>
        )
    }
}